/*
 * @Author: 尚宏浩 aaa@hisense.com
 * @Date: 2024-08-21 08:52:02
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @LastEditTime: 2024-08-23 12:31:39
 * @FilePath: \B2B\src\views\haixinReturn\hisenseReturnDetail\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
//获取渠道订单详情
export function getOrderDetail(params) {
  return service({
    url: "/ecpx/soDetail.nd",
    params
  });
}

export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}