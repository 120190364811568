<template>
    <div class="pur-commodity-total">
        <p class="pur-commodity-total-bulk">
            <span>共 {{ orderDetail.products.length }} 件商品<span style="margin-left: 15px">体积合计{{ new
            Number(totalLoadVolume).toFixed(3)
            }}m³</span></span>
        </p>
        <div class="jia">
            合计：<span class="red">
                <sub style="margin: 0px 0 0px -5px;position: relative;left: 4px;bottom: -1px;">￥</sub>
                {{caleInt}}<span>{{calcSmall}}</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TotalPrice',
    props: {
        orderDetail: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {

        }
    },
    computed: {
        totalLoadVolume: function () {
            let total = 0
            if (this.orderDetail && Array.isArray(this.orderDetail.products)) {
                this.orderDetail.products.forEach(item => {
                    if (item.productInfo?.loadVolume) {
                        total += parseInt(item.productItemNum) * Math.max(item.productInfo.loadVolume, 0)
                    }
                })
            }
            return total
        },

        calcTotalMoney() {
            return this.orderDetail.products.reduce((acc, current) => {
                return acc + Number(current.productItemAmount)
            }, 0).toFixed(2)

        },
        caleInt() {
            return parseInt(this.calcTotalMoney)
        },
        calcSmall() {
            const parts = this.calcTotalMoney.toString().split('.');
            if (parts.length > 1) {
                return '.' + parts[1]
            }

        }
    }
}
</script>

<style lang="less" scoped>
.pur-commodity-total {
    width: 1140px;
    height: 74px;
    background: #F7F7F7;
    border-radius: 8px;
    margin: 16px auto 24px;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    color: #777777;
    line-height: 16px;
    padding: 17px 24px;

    .jia {
        font-weight: 500;
        font-size: 14px;
        color: #262626;
        padding-right: 3px;
        margin-top: 2px;

        .red {
            color: #D9161C !important;
            font-size: 22px !important;
            span{
                color: #D9161C !important;
                font-size: 14px;
            }
        }
    }

    span {
        font-size: 12px;
        // font-weight: 400;
        color: #777777;
        line-height: 16px;
    }

    // }
    .pur-commodity-total-bulk {

        margin: 0;

        img {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
    }

    .pur-commodity-total-price {
        margin: 0 40px 8px 0;
    }

    .pur-commodity-total-prepay {
        margin-right: 40px;

        :nth-child(1) {
            margin-right: 79px;
        }
    }
}
</style>